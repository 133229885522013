/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
require('../css/index.scss');

var $ = require('jquery');
$(function() {
    if($(".master-list").length>0){

        //获取要定位元素距离浏览器顶部的距离
        var navH = $(".master-list").offset().top;
         console.log(navH);
        //滚动条事件
        $(window).scroll(function () {
            //获取滚动条的滑动距离
            var scroH = $(this).scrollTop();
            // console.log(scroH);
            //滚动条的滑动距离大于等于定位元素距离浏览器顶部的距离，就固定，反之就不固定
            if (scroH >= navH) {
                $(".master-list").css({"position": "sticky", "top": 0});
            } else if (scroH < navH) {
                $(".master-list").css({"position": "static"});
            }
        })

    }

    if($("#page.index .car-content .master-list").length>0){

        var height = $(window).height();
        $("#page.index .car-content .master-list").height(height);

    }


    if($("#page.index.index .news-list").length>0){

        //获取要定位元素距离浏览器顶部的距离
        $(document).delegate(".title .item","mouseenter",function(){

            event.preventDefault();
            var category = $(this).attr('category-attr');
            $(this).addClass('active').siblings('.item').removeClass('active');
            that = $(this);
            $.post("/post/more",
                {
                    categoryId:category,

                },
                function(data,status) {
                    if(status){
                        $(".more-news p").attr('max-attr',data.maxTime);
                        $(".more-news p").attr('category-attr',category);
                        $(".news-list-items").html(data.html);
                    }
                });
        });
    }
})